import React, { Component } from "react";
import { Row, Col, Container, Button, Modal } from "reactstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";

import ContactModal from "./ContactModal";

class CtaBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModalChat: false,
    };
  }

  toggleOpenModalChat = () => {
    this.setState({ isOpenModalChat: !this.state.isOpenModalChat });
  };

  render() {
    return (
      <React.Fragment>
        <section className="pt-md-2 bg-primary fixed-bottom shadow">
          <Container>
            <Row className="justify-content-center">
              <Col lg="6" md="6" xs="12" className="text-left">
                <h3>
                  <span className="text-light font-weight-bold d-none d-md-block">
                    {this.props.title}
                  </span>
                </h3>
              </Col>
              <Col lg="6" md="6" xs="12" className="text-md-right text-center">
                <AnchorLink
                  href="#offers"
                  className="btn btn-light mb-2 mr-1"
                  id="cta-order"
                >
                  เลือกสินค้า
                </AnchorLink>
                <button
                  onClick={() =>
                    (window.location.href = "https://s.shopee.co.th/vjVtMVj2")
                  }
                  className="btn btn-danger mb-2 ml-1"
                  id="cta-admin"
                >
                  ซื้อผ่าน Shopee
                </button>
              </Col>
            </Row>
          </Container>
          <Modal
            isOpen={this.state.isOpenModalChat}
            toggle={this.toggleOpenModalChat}
            autoFocus={true}
            centered={true}
          >
            <ContactModal isOpenModalChat={this.state.isOpenModalChat} />
          </Modal>
        </section>
      </React.Fragment>
    );
  }
}
export default CtaBar;
