import Home from "./pages/Home";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
// const Home = import("./pages/Home");
// const TermsOfUse = import("./pages/TermsOfUse"));
// const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
// const ReturnPolicy = React.lazy(() => import("./pages/ReturnPolicy"));

const routes = [
  { path: "/index", component: Home },
  { path: "/terms-of-use", component: TermsOfUse },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/return-policy", component: ReturnPolicy },

  { path: "/", component: Home },
];

export default routes;
